import React from "react"
//import { useIntl, Link, FormattedMessage, changeLocale } from "gatsby-plugin-react-intl"

const ComponentTextblock4 = ({ content }) => {

  return (
    <section className="sect sect_TreatmentCvd_links sect-bg-white sect-overflow">
      <div className="container">
        <h2 className="message-lg" dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </section>
  )
}

export default ComponentTextblock4
