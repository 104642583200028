import React from "react"
//import { useIntl, Link } from "gatsby-plugin-react-intl"

const ComponentColumnRightList = ({ list }) => {
  return (
    <div className="flex-column-4">
      <ul className="list-md">
        {list.map((data) => {
          return <li className="fsi">{data}</li>;
        })}
      </ul>
    </div>
  )
}

export default ComponentColumnRightList
