import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Slider from "react-slick";
import ComponentMain2 from "../../components/main/component-main-2";
import ComponentTreatmentPnd from "../../components/main/component-treatment-pnd";
import ComponentTextblock4 from "../../components/component-textblock-4";
import ComponentColumn2 from "../../components/component-column-2";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const TreatmentCvd = ({ data, location }) => {
  const intl = useIntl();

  //const [isToggleOpen, setToggleOpen] = useState([]);
  //const handleToggle = (e) => {
  //  setToggleOpen({
  //    ...isToggleOpen,
  //    [e.currentTarget.id]: isToggleOpen[e.currentTarget.id] ? false : true,
  //  });
  //};

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    //autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    slidesToShow: 1,
    //centerPadding: '100px',
    focusOnSelect: true,
    //centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 896,
        settings: {
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Layout location={location} locale={intl.locale} mode="beige">
      <Seo
        title={
          intl.locale === "en"
            ? `Revolutionary Robotic treatment for progressive neuromuscular diseases `
            : `Robotische Therapie bei progressiven neuromuskulären Krankheiten`
        }
        description={
          intl.locale === "en"
            ? `Interactive Bio-feedback therapy using HAL enables the regeneration of the impaired motor function for people living with progressive neuromuscular diseases.`
            : `Interaktive Biofeedback Therapie mit HAL ermöglicht Regeneration von gestörten motorischen Funktionen bei Menschen mit progressiven neuromuskulären Erkrankungen.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/30spG8dUj9zO9eMGHPOw1R/2deb893cdd00e8966349420b14e6c20e/E179.jpg?w=1200&h=630&fit=fill"
      />

      <ComponentMain2
        title={
          intl.locale === "en" ? (
            <span>
              Enhance Neuro-
              <br />
              modulation
            </span>
          ) : (
            `Fördern der Neuromodulation`
          )
        }
        label={
          intl.locale === "en"
            ? `Progressive neuromuscular diseases`
            : `Progressive neuromuskuläre Erkrankungen`
        }
        content={
          intl.locale === "en"
            ? `HAL functions in accordance with the motor intention of the wearer and internal ideal movement patterns as if it were a part of the body.`
            : `HAL funktioniert im Gleichklang mit der Bewegungsabsicht und des innerlichen Bewegungsmusters des Trägers, als wäre der HAL Teil des Körpers.`
        }
        image="/images/treatment/pnd/26.jpg"
      />

      <ComponentTextblock4
        content={
          intl.locale === "en"
            ? `"In the present study, we propose that cybernics (i.e., the iBF created by the union of HAL and the nervous and musculoskeletal systems of the wearer) enables the regeneration of the impaired motor function. This neuroplasticity would result in the reconnection of the CNS to the motor units and the reconnection of motor neurons to muscle fibres' (Nakajima et al., 2021).`
            : `"In der vorliegenden Studie, deuten wir an, dass Cybernics (das interaktive Biofeedback, erzeugt durch die Einheit von HAL und dem Nerven- sowie Muskoloskeletalensystems des Träger) die Regeneration von gestörten motorischen Funktionen ermöglicht. Die Neuroplastizität  kann zur Wiederanbindung des zentralen Nervensystems an die motorischen Einheiten und der motorischen Neuronen an die Muskelfasern führen" (Nakajima et al., 2021).`
        }
      />

      <section className="bg-white">
        <div className="container">
          <div className="flex-row mx-n2 mb-6 pict-3">
            <div className="px-2">
              <StaticImage
                src="../../images/treatment/pnd/27.jpg"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/treatment/pnd/28.jpg"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/treatment/pnd/29.jpg"
                className="mb-4"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_third bg-white">
        <div className="container">
          <ComponentColumn2
            lefttitle={
              intl.locale === "en"
                ? `Promote neuromuscular reconnection and remodulation`
                : `Förderung neuromuskulärer Wiederanbindung und Remodulation`
            }
            leftcontent={
              intl.locale === "en"
                ? `Even if the muscles barely function, the sensory receptors such as those found in muscle spindle fibers get activated by the device’s movement, and this sensory information is fed back to the brain in real-time. Due to this neurological loop of information between the brain and the periphery can be established repeatedly without putting much load on the muscles, synaptic connections can be improved and strengthened through neuronal plasticity, reconstructing the neural connectivity network and thereby promoting functional improvement. In this way, the technological characteristics of the HAL Systems were designed with the intent to promote the reconstruction of the patient’s neural connectivity network including the connectome of the brain, and improve and regenerate the patient’s brain-neuro-physical functions.`
                : `Auch wenn in den Muskeln kaum Restfunktion vorhanden ist, werden sensorische Rezeptoren, die u.a. in den Muskelspindeln zu finden sind, durch die Bewegung des Gerätes aktiviert. Die Rezeptoren senden dann sensorische Informationen in Echtzeit zurück an das Gehirn. Dies ermöglicht es, die neurologische Informationsschleife zwischen Gehirn und Peripherie ohne große muskuläre Belastung wiederholt aufzubauen, synaptische Verbindungen zu verbessern und die neuronale Plastizität zu stärken. Außerdem wird das neuronale Netzwerk rekonstruiert und so funktionelle Verbesserungen erreicht. Vor diesem Hintergrund wurden die technologischen Eigenschaften des HAL-Systems konzipiert. Die Intention bei der Entwicklung von HAL ist, die Rekonstruierung des neuronalen Netzwerkes inklusive des Konnektoms des Gehirns zu fördern und Verbesserungen sowie die Regeneration von Gehirn-/ neurophysikalischen Funktionen zu ermöglichen.`
            }
            label=""
            list={
              intl.locale === "en"
                ? [
                    "By using HAL, we can avoid providing excessive load and fatigue on the neuromuscular system",
                    "Neuromuscular and central nervous systems work to produce the intended motions with HAL",
                    "In the five years performance test, the data was collected from a total of 218 patients from 20 medical institutions. The result suggests a high efficacy towards patients with intractable progressive neuromuscular diseases.",
                  ]
                : [
                    "Durch die Nutzung des HAL können wir eine übermäßige Belastung und Ermüdung des neuromuskulären Systems vermeiden.",
                    "Das neuromuskuläre System und das zentrale Nervensystem arbeiten zusammen, um die beabsichtigte Bewegung mit dem HAL auszuführen.",
                    "Für die fünfjährige Leistungsüberprüfung wurden Daten von insgesamt 218 Patienten und 20 medizinischen Einrichtungen gesammelt. Die Ergebnisse zeigen eine hohe Effektivität für Patienten mit hartnäckigen progressiven neuromuskulären Erkrankungen",
                  ]
            }
          />
        </div>
      </section>

      <ComponentTreatmentPnd
        image="/images/treatment/pnd/30.jpg"
      />

      <section className="bg-bge py-8">
        <div className="container">
          <div className="flex-row">
            <div className="flex-column-7">
              <h2 className="h3">
                {intl.locale === "en"
                  ? `The five years performance test suggests a high efficacy towards
  patients with progressive neuromuscular diseases`
                  : `Die fünfjährige Leistungsüberprüfung zeigt eine hohe Effektivität für Patienten mit progressiven neuromuskulären Erkrankungen`}
              </h2>
              <p>
                {intl.locale === "en"
                  ? `For individuals having progressive neuromuscular disease
  including spinal muscular atrophy, spinal and bulbar muscular
  atrophy, amyotrophic lateral sclerosis, Charcot-Marie-Tooth
  disease, distal muscular dystrophy, inclusion body myositis,
  congenital myopathy, muscular dystrophy, an
  investigator-initiated randomized controlled crossover clinical
  study approved by the Ministry of Health, Labour and Welfare of
  Japan was conducted and the results were used to gain medical
  device approval in Japan.`
                  : `Für Patienten mit progressiven neuromuskulären Erkrankungen einschließlich spinaler Muskelatrophien (SMA), Amyotrophe Lateralsklerose (ALS), Charcot-Marie-Tooth-Krankheit (CMT), distale Muskeldystrophien, Einschlusskörper-Myositis (IBM), kongenitale Myopathie und Muskeldystrophien, wurde eine nicht kommerzielle, klinisch randomisierte und kontrollierte Crossover-Studie durch das japanische Ministerium für Gesundheit, Arbeit und Soziales durchgeführt. `}
              </p>
              {intl.locale === "en" ? (
                <p>
                  The results suggest that treatment with the HAL device shows
                  improvement in gait related outcome measures despite the
                  progressive nature of the diseases. Additional to the
                  literature and clinical trial, the findings from the post
                  market survey bears significant weight and are quickly
                  summarized below:
                  <br />
                  <br />
                  1) Participants showed improvement in gait related outcome
                  measures comparing pre-post intervention of the first cycle of
                  treatment (9 sessions).
                  <br />
                  <br />
                  2) Even after 1.5 years from the measurement of baseline, with
                  intermittent treatment cycles participants showed about +20%
                  difference from the baseline function, despite the progressive
                  nature of their disease.
                  <br />
                  <br />
                  3) Blood creatine kinase data was
                  <br />
                  collected from a total of 100 participants and results show a
                  decreasing trend when comparing pre-post HAL treatment
                  measurements. The lack of rise in CK levels suggests that HAL
                  treatment does not damage the muscles through overuse.
                </p>
              ) : (
                <p>
                  Die Ergebnisse wurden genutzt, um die japanische Zulassung als
                  medizinisches Produkt zu erlangen. Zudem zeigen die
                  Ergebnisse, dass die Therapie mit HAL zu Verbesserungen bei
                  gangspezifischen Messparametern führt, und dies trotz der
                  fortschreitenden Art der Erkrankungen. Neben den vorliegenden
                  klinischen Studien sind die weiteren Beobachtungen nach der
                  Markteinführung von Bedeutung, diese lassen sich wie folgt
                  kurz zusammenfassen:
                  <br />
                  <br />
                  1) Studienteilnehmer zeigten in einem Vorher-Nachher-Vergleich
                  nach dem ersten Therapieabschnitt (neun Einheiten)
                  Verbesserungen bei gangspezifischen Messparametern.
                  <br />
                  <br />
                  2) Auch eineinhalb Jahre nach den Ausgangsmessungen führten
                  periodische Therapieszyklen zu einer Verbesserung von über 20%
                  im Vergleich zur Ausgangssituation, und dies trotz der
                  fortschreitenden Art der Erkrankung.
                  <br />
                  <br />
                  3) Bei 100 Patienten wurde der Kreatinkinasewert im Blut
                  gemessen. Die Ergebnisse zeigen eine abnehmende Tendenz im
                  Vorher-Nachher-Vergleich. Der ausbleibende Anstieg des
                  Kreatinkinasewertes deutet darauf hin, dass das HAL-Training
                  die Muskeln nicht durch Übertraining schädigt
                </p>
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_2f8as2">
        <div className="container">
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en"
              ? `Examples from Therapy`
              : `Therapiebeispiele`}
          </p>
          <p className="h3 font-weight-bold">
            {intl.locale === "en"
              ? `Watch the videos to see how HAL is used in therapy`
              : `Schauen Sie sich die Videos an und bekommen Sie einen Eindruck von der HAL-Therapie`}
          </p>
          <div className="slide">
            <Slider {...settings}>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Uvzq4Wk0yls"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Training on a treadmill with HAL Lower Limb`
                      : `Laufbandtraining mit dem HAL Lower Limb`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Z1TCVD_T5tQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `Straightening your upper body with HAL Lumbar Type`
                      : `Aufrichtung des Oberkörpers mit dem HAL Lumbar Type`}
                  </p>
                </div>
              </div>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/xGSZxEhiBlE"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `HAL Lumbar Type helps you to stand up`
                      : `HAL Lumbar Type erleichtert das Aufstehen`}
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-rot">
        <div className="container">
          <p className="h2 mb-5">
            {intl.locale === "en"
              ? `Learn more about HAL Treatment`
              : `Mehr über das HAL Training erfahren`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Cerebrovascular disease`
                    : `Zerebrovaskulären Erkrankungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `From the perspective of motor learning, repetitive movement
  and voluntary exercise are important elements`
                    : `Aus Sicht des motorischen Lernen sind wiederholte Bewegungen und willkürlich durchgeführte Aufgaben wichtige Elemente`}
                </p>
                <Link to="/treatment/spinal/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Spinal Cord Injury`
                    : `Rückenmarksverletzungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Locomotor training performed using a neurologically controlled
  cyborg HAL enables the neural activity and repetitive
  excercises of specific tasks.`
                    : `Lokomotionstraining mit dem neurologisch kontrollierten Cyborg HAL ermöglicht neuronale Aktivität und wiederholte Durchführung spezifischer Aufgaben.`}
                </p>
                <Link to="/treatment/cvd/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `About ZNB - Our Therapist`
                    : `Das ZNB / Unsere Therapeuten`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Meet our team. We offer exceptional level of personalised
  skill and expertise.`
                    : `Treffen Sie unser Team. Wie bieten ein außergewöhnliches Level an individuellen Fähigkeiten und Fachwissen. `}
                </p>
                <Link to="/treatment/znb/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Testimonials` : `Erfahrungsberichte`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Discover real stories from real people about how Cyberdyne
  technology changed their lives forever.`
                    : `Entdecken Sie reale Geschichten von Patienten darüber wie Cyberdyne deren Leben für immer verändert hat.`}
                </p>
                <div>
                  <Link to="/treatment/testimonials/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Frequently Asked Questions`
                    : `Häufig gestellte Fragen (FAQ)`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Here are our general frequently asked questions. For more
  specific questions, please contact us directly.`
                    : `Hier finden Sie häufig gestellte Fragen. Für spezifische Fragen kontaktieren Sie uns bitte direkt.`}
                </p>
                <div>
                  <Link to="/treatment/faq/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_2f8as2">
        <div className="container">
          <p className="h3 font-weight-bold">
            {intl.locale === "en"
              ? `HAL— Improving Mobility Wellness One Step at a Time`
              : `HAL - Verbessert das Bewegungswohlbefinden Schritt für Schritt`}
          </p>
          <p>
            {intl.locale === "en"
              ? `Gain a deeper understanding of the science and medicine of motor
            function and neural connections through the HAL Lower Limb.`
              : `Bekommen Sie ein besseres Verständnis für die wissenschaftlichen und medizinischen Aspekte der motorischen Funktion und neuronalen Verbindungen des HALs.`}
          </p>
          <div>
            <Link to="/contact/" className="link-button">
              {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TreatmentCvd;
