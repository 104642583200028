import React from "react"

const ComponentMain2 = ({ image, title, label, content }) => {
  return (
    <section className="sect sect_TreatmentCvd_first sect-bg-white pt-0">
        <div className="main_back sect-bg-bge"></div>
        <div className="main_copy">
          <h1 className="h1">{title}</h1>
          <p className="message-label-bold txt txt-rot">{label}</p>
          <p dangerouslySetInnerHTML={{ __html: content }}  />
        </div>
        <div className="main_img">
          <img
            src={image}
            alt="ImgTreatmentCatch"
            className="ImgHeader"
          />
        </div>
    </section>
  )
}

export default ComponentMain2
