import React from "react";
//import { useIntl, Link } from "gatsby-plugin-react-intl";
import ComponentColumnRightList from "./component-column-right-list";

const ComponentColumn2 = ({
  lefttitle,
  leftcontent,
  leftcontent2,
  leftcontent3,
  leftcontent4,
  label,
  list,
}) => {
  const dispLabel = label ? (
    <p if className="message-label-bold txt txt-rot">
      {label}
    </p>
  ) : (
    ""
  );
  const leftContent2 = leftcontent2 ? (
    <p dangerouslySetInnerHTML={{ __html: leftcontent2 }} />
  ) : (
    ""
  );
  const leftContent3 = leftcontent3 ? (
    <p dangerouslySetInnerHTML={{ __html: leftcontent3 }} />
  ) : (
    ""
  );
  const leftContent4 = leftcontent4 ? (
    <p dangerouslySetInnerHTML={{ __html: leftcontent4 }} />
  ) : (
    ""
  );
  return (
    <div className="flex-row">
      <div className="flex-column-8 pr-5">
        {dispLabel}
        <p className="h3 font-weight-bold">{lefttitle}</p>
        <p dangerouslySetInnerHTML={{ __html: leftcontent }} />
        {leftContent2}
        {leftContent3}
        {leftContent4}
      </div>
      <ComponentColumnRightList list={list} />
    </div>
  );
};

export default ComponentColumn2;
