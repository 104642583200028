import React, { useState } from "react";
//import { StaticImage } from "gatsby-plugin-image";
import {
  useIntl,
  //Link,
  //FormattedMessage,
  //changeLocale,
} from "gatsby-plugin-react-intl";
import Slider from "react-slick";

const ComponentMain5 = ({ image, children }) => {
  const intl = useIntl();

  const [pos, setPos] = useState(0);
  const isActiveBenefit = () => {
    return pos === 0 ? "active" : "";
  };
  const isActiveScience = () => {
    return pos === 1 ? "active" : "";
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    //autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    slidesToShow: 1,
    //centerPadding: '100px',
    focusOnSelect: true,
    //centerMode: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 896,
        settings: {
          centerMode: false,
        },
      },
    ],
  };
  return (
    <section className="sect sect_TreatmentCvd_iuh234 bg-white">
      <div className="container">
        <div className="container-block flex-row top-border-container">
          <div className="flex-column-12">
            <div className="flex-row top-border-wrap mb-8">
              <div className="flex-column-4 px-4">
                <div
                  className={"top-border " + isActiveBenefit()}
                  onClick={() => setPos(0)}
                  onKeyDown={() => setPos(1)}
                  role = "button"
                  tabIndex={0}
                >
                  {intl.locale === "en" ? `The Benefits` : `Die Vorteile`}
                </div>
              </div>
              <div className="flex-column-4 px-4">
                <div
                  className={"top-border " + isActiveScience()}
                  onClick={() => setPos(1)}
                  onKeyDown={() => setPos(1)}
                  role = "button"
                  tabIndex={0}
                >
                  {intl.locale === "en" ? `Sciences` : `Publikationen`}
                </div>
              </div>
            </div>
          </div>
          <div className="container-block-imgContainer flex-column-6">
            <img
              src={image}
              alt="ImgTreatment"
              loading="eager"
              className="Imgblock01"
            />
          </div>
          <div className="container-block-txtContainer flex-column-6">
            <div className="px-6 py-0">
              {pos === 0 ? (
                <div>
                  <h2 className="h3">
                    {intl.locale === "en"
                      ? `Patients with progressive neuromuscular diseases can profit from HAL training in various ways:`
                      : `Patienten mit neuromuskulären Erkrankungen können auf vielfältige Weise von der HAL-Therapie profitieren:`}
                  </h2>
                  {intl.locale === "en" ? (
                    <p>
                      • Preserves and improves gait ability
                      <br />
                      • Improves endurance
                      <br />• Positive psychological effects
                    </p>
                  ) : (
                    <p>
                      • Erhalt und Verbesserung der Gehfähigkeit
                      <br />
                      • Verbessert die Ausdauerfähigkeit
                      <br />• Positive Effekte auf die Psyche
                    </p>
                  )}
                </div>
              ) : (
                <div className="slide slide-single">
                  <Slider {...settings}>
                    <div className="slide__link">
                      <div>
                        <h2 className="h3">
                          {intl.locale === "en"
                            ? `The successful intervention of cybernic treatment are truly
      ground-breaking`
                            : `Die erfolgreiche Anwendung von Cybernic- Therapien ist bahnbrechend.`}
                        </h2>
                        <p>
                          {intl.locale === "en"
                            ? `HAL is a new treatment device for walking exercise, proven to
      be more effective than the conventional method in patients
      with incurable neuromuscular diseases.`
                            : `Der HAL ist ein neuartiges Therapieprodukt für Gehübungen, das nachgewiesen hat effektiver als konventionelle Therapien bei Patienten mir unheilbaren neuromuskulären Erkrankungen zu sein.`}
                        </p>
                        <p className="fsi">
                          {intl.locale === "en"
                            ? `"Cybernic treatment with wearable cyborg Hybrid Assistive Limb
      (HAL) improves ambulatory function in patients with slowly
      progressive rare neuromuscular diseases: a multicentre,
      randomised, controlled crossover trial for efficacy and safety
      (NCY-3001)`
                            : `"Cybernic treatment with wearable cyborg Hybrid Assistive Limb (HAL) improves ambulatory function in patients with slowly progressive rare neuromuscular diseases: a multicentre, randomised, controlled crossover trial for efficacy and safety (NCY-3001)"`}
                        </p>
                        <div>
                          <a
                            href="https://ojrd.biomedcentral.com/articles/10.1186/s13023-021-01928-9"
                            className="link-simple"
                          >
                            {intl.locale === "en" ? `Read` : `Lesen`}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="slide__link">
                      <div>
                        <h2 className="h3">
                          {intl.locale === "en"
                            ? `HAL therapy can help ALS patients to maintain their abilitiy to walk
      ground-breaking`
                            : `HAL Therapie kann Patienten mit ALS helfen ihre Gehfähigkeit zu erhalten`}
                        </h2>
                        <p>
                          {intl.locale === "en"
                            ? `After 10 sessions of HAL treatment ALS patients were able to increase their walking distance in the 2 minute walking test, as well as their cadence.                        `
                            : `Nach 10 Einheiten HAL-Therapie konnten Patienten ihre Gehstrecke im 2-Minuten Gehtest steigern und den Gangrhythmus verbessern.                        `}
                        </p>
                        <p className="fsi">
                          {intl.locale === "en"
                            ? `"Robot-assisted training using hybrid assistive limb ameliorates gait ability in patients with amyotrophic lateral sclerosis"`
                            : `"Robot-assisted training using hybrid assistive limb ameliorates gait ability in patients with amyotrophic lateral sclerosis"`}
                        </p>
                        <div>
                          <a
                            href="https://www.sciencedirect.com/science/article/pii/S0967586822000856"
                            className="link-simple"
                          >
                            {intl.locale === "en" ? `Read` : `Lesen`}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="slide__link">
                      <div>
                        <h2 className="h3">
                          {intl.locale === "en"
                            ? `HAL Training is safe to be performed with patients suffering from limb-girdle muscular dystrophy (LGMD)                        `
                            : `Die HAL-Therapie kann bei Patienten mit Gliedergürteldystrophie sicher und effektiv angewendet werden                        `}
                        </h2>
                        <p>
                          {intl.locale === "en"
                            ? `Participants were able to improve their endurance without any adverse effects. Positive outcomes persisted for at least 6 weeks.                        `
                            : `Die Teilnehmer dieser Studie konnten ihre Ausdauer durch das HAL-Training steigern, ohne dass es zu negativen Vorkommnissen kam.                        `}
                        </p>
                        <p className="fsi">
                          {intl.locale === "en"
                            ? `"Treadmill Training with HAL Exoskeleton-A Novel Approach for Symptomatic Therapy in Patients with Limb-Girdle Muscular Dystrophy-Preliminary Study"`
                            : `"Treadmill Training with HAL Exoskeleton-A Novel Approach for Symptomatic Therapy in Patients with Limb-Girdle Muscular Dystrophy-Preliminary Study"`}
                        </p>
                        <div>
                          <a
                            href="https://www.frontiersin.org/articles/10.3389/fnins.2017.00449/full"
                            className="link-simple"
                          >
                            {intl.locale === "en" ? `Read` : `Lesen`}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="slide__link">
                      <div>
                        <h2 className="h3">
                          {intl.locale === "en"
                            ? `HAL Therapy increases walking distance and walking speed                        `
                            : `HAL-Therapie steigert die Gehstrecke und Gehgeschwindigkeit                        `}
                        </h2>
                        <p>
                          {intl.locale === "en"
                            ? `Three patients underwent HAL-therapy and showed improvements in the 2-minute walking test and the speed in 6 meter walking test. Further positive psychological effects are shown.                        `
                            : `Drei Patienten mit sporadische Einschlusskörpermyositis (sIBM) konnten sich im Zuge der HAL-Therapie im 2-Minuten-Gehtest und in der Geschwindigkeit im 6-Meter-Gehtest verbessern.                        `}
                        </p>
                        <p className="fsi">
                          {intl.locale === "en"
                            ? `"Hybrid Assistive Limb® for sporadic inclusion body myositis: A case series"                        `
                            : `"Hybrid Assistive Limb® for sporadic inclusion body myositis: A case series"`}
                        </p>
                        <div>
                          <a
                            href="https://www.jocn-journal.com/article/S0967-5868(20)31510-1/fulltext"
                            className="link-simple"
                          >
                            {intl.locale === "en" ? `Read` : `Lesen`}
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="slide__link">
                      <div>
                        <h2 className="h3">
                          {intl.locale === "en"
                            ? `HAL-Therapy has the potential to improve the quality of life in patients with neuromuscular diseases
                        `
                            : `Die HAL-Therapie hat das Potential die Lebensqualität, bei Patienten mit neuromuskulären Erkrankungen, zu verbessern.                        `}
                        </h2>
                        <p>
                          {intl.locale === "en"
                            ? `Participants improved their walking distance and walking speed. Further these improvements seem to have a positive effect on the quality of life.                         `
                            : `Die Teilnehmer dieser Studie konnten die Länger ihrer Gehstrecke, sowie die Gehgeschwindigkeit steigern. Dies scheint wiederum positive Effekte auf die Lebensqualität zu haben.                        `}
                        </p>
                        <p className="fsi">
                          {intl.locale === "en"
                            ? `"Effects of gait training with a voluntary-driven wearable cyborg, Hybrid Assistive Limb (HAL), on quality of life in patients with neuromuscular disease, able to walk independently with aids"`
                            : `"Effects of gait training with a voluntary-driven wearable cyborg, Hybrid Assistive Limb (HAL), on quality of life in patients with neuromuscular disease, able to walk independently with aids"`}
                        </p>
                        <div>
                          <a
                            href="https://www.jocn-journal.com/article/S0967-5868(21)00205-8/fulltext"
                            className="link-simple"
                          >
                            {intl.locale === "en" ? `Read` : `Lesen`}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="slide__link">
                      <div>
                        <h2 className="h3">
                          {intl.locale === "en"
                            ? `HAL training improves walking ability without damaging skeletal muscles                        `
                            : `HAL-Training verbessert die Gehfähigkeit ohne die Skelettmuskulatur zu schädigen                        `}
                        </h2>
                        <p>
                          {intl.locale === "en"
                            ? `Improvements in walking distance measured by the 2-minute walk test and walking speed in the 10-meter walk test are shown. In addition measurements of muscle enzymes show that there is no damage to skeletal muscles.                        `
                            : `Sowohl die Gehstrecke im 2-Minuten Gehtest als auch die Gehgeschwindigkeit im 10-Meter Gehtest konnten verbessert werden. Zugleich hat die Messung von Muskelenzymen gezeigt, dass die Skelettmuskulatur keinen Schaden nimmt.                        `}
                        </p>
                        <p className="fsi">
                          {intl.locale === "en"
                            ? `"Effect of gait training using Hybrid Assistive Limb on gait ability and the risk for overwork weakness in the lower limb muscles in patients with neuromuscular disease: a proof-of-concept study"`
                            : `"Effect of gait training using Hybrid Assistive Limb on gait ability and the risk for overwork weakness in the lower limb muscles in patients with neuromuscular disease: a proof-of-concept study"`}
                        </p>
                        <div>
                          <a
                            href="https://www.minervamedica.it/en/journals/europa-medicophysica/article.php?cod=R33Y2021N05A0720"
                            className="link-simple"
                          >
                            {intl.locale === "en" ? `Read` : `Lesen`}
                          </a>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComponentMain5;
